/* margin ----------- */

.m-10 {margin: 10px}
.m-15 {margin: 15px}
.m-20 {margin: 20px}
.m-30 {margin: 30px}

/* margin-top */
.mt-0 {margin-top: 0}
.mt-10 {margin-top: 10px}
.mt-15 {margin-top: 15px}
.mt-20 {margin-top: 20px}
.mt-30 {margin-top: 30px}

/* margin-bottom */
.mb-0 {margin-bottom: 0}
.mb-10 {margin-bottom: 10px}
.mb-15 {margin-bottom: 15px}
.mb-20 {margin-bottom: 20px}
.mb-30 {margin-bottom: 30px}

/* margin-left */
.ml-5 {margin-left: 5px}
.ml-10 {margin-left: 10px}
.ml-15 {margin-left: 15px}
.ml-20 {margin-left: 20px}
.ml-25 {margin-left: 25px}
.ml-30 {margin-left: 30px}

/* margin-right */
.mr-5 {margin-right: 5px}
.mr-10 {margin-right: 10px}
.mr-15 {margin-right: 15px}
.mr-20 {margin-right: 20px}
.mr-25 {margin-right: 25px}
.mr-30 {margin-right: 30px}

/* padding ----------- */

.p-10 {padding: 10px}
.p-15 {padding: 15px}
.p-20 {padding: 20px}
.p-30 {padding: 30px}

/* padding-top */
.pt-10 {padding-top: 10px}
.pt-15 {padding-top: 15px}
.pt-20 {padding-top: 20px}
.pt-25 {padding-top: 25px}
.pt-30 {padding-top: 30px}

/* padding-bottom */
.pb-10 {padding-bottom: 10px}
.pb-15 {padding-bottom: 15px}
.pb-20 {padding-bottom: 20px}
.pb-25 {padding-bottom: 25px}
.pb-30 {padding-bottom: 30px}

/* padding-left */
.pl-5 {padding-left: 5px}
.pl-10 {padding-left: 10px}
.pl-15 {padding-left: 15px}
.pl-20 {padding-left: 20px}
.pl-25 {padding-left: 25px}
.pl-30 {padding-left: 30px}
.pl-80 {padding-left: 80px}


/* padding-right */
.pr-5 {padding-right: 5px}
.pr-10 {padding-right: 10px}
.pr-15 {padding-right: 15px}
.pr-20 {padding-right: 20px}
.pr-25 {padding-right: 25px}
.pr-30 {padding-right: 30px}

/* width ----------- */

.w-10 {width: 10px}
.w-25 {width: 25px}
.w-30 {width: 25px}
.w-50 {width: 50px}
.w-75 {width: 75px}
.w-100 {width: 100px}
.w-110 {width: 110px}
.w-120 {width: 120px}
.w-125 {width: 125px}
.w-150 {width: 150px}
.w-175 {width: 175px}
.w-180 {width: 180px}
.w-200 {width: 200px}
.w-225 {width: 225px}
.w-250 {width: 250px}
.w-275 {width: 275px}
.w-300 {width: 300px}
.w-350 {width: 350px}
.w-370 {width: 370px}
.w-400 {width: 400px}
.w-450 {width: 450px}
.w-500 {width: 500px}

.h-80 {height: 80px}
.h-100 {height: 100px}
.h-150 {height: 150px}
.h-200 {height: 200px}

.hp-100 {height: 100%}

.wp-30 {width: 30%}
.wp-50 {width: 50%}
.wp-85 {width: 85%}
.wp-95 {width: 95%}
.wp-100 {width: 100%}

.mw-25 {min-width: 25px}
.mw-50 {min-width: 50px}
.mw-75 {min-width: 75px}
.mw-100 {min-width: 100px}
.mw-125 {min-width: 125px}
.mw-150 {min-width: 150px}
.mw-200 {min-width: 200px}
.mw-250 {min-width: 250px}
.mw-300 {min-width: 300px}
.mw-350 {min-width: 350px}
.mw-400 {min-width: 400px}

.mxw-25 {max-width: 25px}
.mxw-50 {max-width: 50px}
.mxw-75 {max-width: 75px}
.mxw-100 {max-width: 100px}
.mxw-125 {max-width: 125px}
.mxw-150 {max-width: 150px}
.mxw-200 {max-width: 200px}
.mxw-250 {max-width: 250px}
.mxw-300 {max-width: 300px}
.mxw-350 {max-width: 350px}
.mxw-370 {max-width: 370px}
.mxw-400 {max-width: 400px}

/* other ----------- */

.d-block {display: block}
.d-flex {display: flex}
.d-flex-wrap {display: flex; flex-wrap: wrap}
.d-flex-wrap-sb {display: flex; flex-wrap: wrap; justify-content: space-between;}
.d-flex-wrap-ac {display: flex; flex-wrap: wrap; align-items: center}
.d-flex-ac {display: flex; align-items: center}
.d-flex-sb {display: flex; justify-content: space-between;}
.d-flex-end {display: flex; justify-content: flex-end;}
.d-flex-center {display: flex; justify-content: center; align-items: center}

.ta-left {text-align: left}
.ta-center {text-align: center}
.ta-right {text-align: right}

.fs-10 {font-size: 10px}
.fs-12 {font-size: 12px}
.fs-14 {font-size: 14px}

.fs-10-i {font-size: 10px; font-style: italic;}
.fs-12-i {font-size: 12px; font-style: italic;}
.fs-14-i {font-size: 14px; font-style: italic;}

.fcblack {
    color: #000000;
}

.white {background: #FFF;}

.fcred {
    color: var(--url-color)
}

.fcwhite {
    color: var(--bg-color-main)
}


@media (min-width: 0px) and (max-width:450px) {

    .w-300 {
        width: 280px
    }

    .w-250 {
        width: 220px
    }
}
